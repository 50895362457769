.info-table td {
    height: 50px;
}

p {
    padding: 0;
    margin: 0;
}

.description {
    vertical-align: text-top;
    font-size: 0.75em;
    text-align: left;
    padding: 2px 0 0 8px;
    line-height: 1em;
}

.notes {
    padding: 20px 0;
    line-height: 1.2em;
}

.definition {
    padding: 10px 0;
    margin: 0;
    text-align: left;
}

.term {
    color: #fff;
}

#defs {
    padding: 20px 0;
    line-height: 1.2em;
}
