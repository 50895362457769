.App {
    height: 100%;
    width: 100%;
    font-family: 'Montserrat';
}

@media(min-width:1025px) {
    .search-few {
        background-attachment: fixed;
    }

    .forecast-view {
        background-attachment: fixed;
    }
}
.search-view {

    height: 100vh;
    background: #161415 url('assets/search-bg.jpg');
    background-size: auto 100%;
    background-position: center center;
    text-align: center;
    background-repeat: no-repeat;
}

.spacer {
    height: 20vh;
}

.divider {
    border-color: #fff;
    border-style: solid;
    border-width: 0 0 1px;
    width: 60%;
    height: 1px;
    margin: 10px auto;
}

.responsive-header {
    font-family: 'Merriweather';
    font-size: 2.1em;
    letter-spacing: 1px;
    font-style: bold;
    color: #fff;
    margin-bottom: 12px;
    text-shadow: 0px 1px 3px rgba(0,0,0,0.8);
}

.forecast-view {

    position: relative;
    background-image: url('assets/forecast-bg.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}

.info-view {
    text-align: center;
    position: relative;
    height: 100vh;
    width: 90vw;
    margin: auto;
}

.flex-container {
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.searched {
    padding: 20px 0;
    font-size: 2em;
    color: white;
    text-align: center;
}

.info {
    padding-top: 30px;
    color: #fff;
}

.info-table {
    margin: auto;
}

.attribution {
    max-width: 80vw;
    max-height: 300px; 
}

/* 
 *  768w for tablet, 1024 for desktop
 */

    @media(min-width:940px) {

        .responsive-header {
            font-size: 3em;
        }

        table {
            max-width: 35vw;
        }

        td {
            font-size: 1.5em;
        }

        .divider {
            max-width: 25vw;
        }

        .title {
            padding-left: 20px;
        }

        .flex-container {
        }

        .searched {
            margin-top: 10vh;
        }
        .search-view {
            background: #161415 url('assets/search-bg-lg.jpg');
            background-attachment: fixed;
            background-size: cover;
        }

        .forecast-view {
            background-image: url('assets/forecast-bg-lg.jpg');
            background-attachment: fixed;
        }

        .notes {
            font-size: 1em;
        }

        .description {
            font-size: 0.9em;
        }

        #defs {
            max-width: 50vw;
            margin: auto;
            padding-top: 20px;
        }

        .attribution {
            max-height: 200px;
        }

        .info-spacer {
            height: 5vh;
        }
    }

