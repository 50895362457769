.forecast {
    color: white;
    table-layout: fixed; 
    background-color: rgba(0,0,0,0.3);
    padding: 0;
    margin: auto;
    width: 80vw;
}

.weekday {
    text-transform: capitalize;
    padding: 4px;
    text-align: center;
    width: 100%;
}

.day {
    width: 100%;
    font-size: 2em;
}

.poor {
    width: 50%;
    background-color: green;
    vertical-align: middle;
}

.title {
    padding-left: 10px;
}

.center-text {
    text-align: center;
    vertical-align: middle;
}

.peak {
    background-color: #00C805;
}

.good {
    background-color: #359603;
}

.okay {
    background-color: #6A6402;
}

.poor {
    background-color: #9F3201;
}

.bad {
    background-color: #DF0000;
}

.inner-span {
    padding-right: 5px;
}
