#search-form {
    position: relative;
    width: 100%;
    text-align: center;
}

.search-field {
    display: block;
    width: 60vw;
    margin: auto;
    margin-bottom: 3px;
    border-radius: 10px;
    padding: 0 8px;
}

.submit {
    display: inline-block;
    width: auto;
    margin: auto;
    font-family: sans-serif;
    color: #555;
}

.button {
    width: 100px;
}

.suggestions {
    z-index: 999;
    position: absolute;
    width: 85vw;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 0px;
    list-style: none;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    text-align: left;
}

.suggestion-item {
    padding: 2px;
    height: 24px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.suggestion-item:hover{
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.2);
}

@media(min-width: 1025px) {
    .suggestions {
        width: 30vw;
    }

    .search-field {
        width: 20vw;
    }
}
